import { ApolloClient, InMemoryCache, useQuery, ApolloProvider } from '@apollo/client';
import ReactHtmlParser from 'react-html-parser';
import { gql } from 'graphql-tag';
import React from "react";
import CarBattery from './icons/car-battery';
import BrakeDisc from './icons/brake-disc';
import Oil from './icons/oil';
import Talk from './icons/talk';
import Tyre from './icons/tyre';
import CMSIcon from './icons/cms-icon';
import Catalyst from './icons/catalyst';
import OperationalFluid from './icons/operational-fluid';
import HazardousSubstance from './icons/hazardous-substance';
import WorkshopWaste from './icons/workshop-waste';



const WASTE_TYPES_CARDS_QUERY = gql`
query wasteTypesCardsQuery($id: ID!, $lng: String!, $siteId: String!) {
  cms(lng: $lng, siteId: $siteId) {
    articleContent(id: $id, dateSensitive: true) {
      id
      title
      content
      children {
        articles(perPage: 50) {
          nodes {
            title
            teaser
            content
            urlCustom
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}

`;

const client = new ApolloClient({
	uri: 'https://api.intercars.eu/graphql',
	cache: new InMemoryCache(),
});

const Card = function({ node, index }) {

  const nodeDetails = {
    'services/battery-purchase': {
      icon: <CarBattery />,
      url: 'https://bio-service.pl/pl/uslugi/akumulatory'
    },
    'services/tire-disposal': {
      icon: <Tyre/>,
      url: 'https://bio-service.pl/pl/uslugi/opony'
    },
    'services/scrap-purchase': {
      icon: <BrakeDisc />,
      url: 'https://bio-service.pl/pl/uslugi/tarcze-i-bebny-hamulcowe'
    },
    'services/waste-oil-collection': {
      icon: <Oil />,
      url: 'https://bio-service.pl/pl/uslugi/oleje'
    },
    'services/operating-fluids-disposal': {
      icon: <OperationalFluid />,
      url: 'https://bio-service.pl/pl/uslugi/skup-oleju-przepracowanego'
    },
    'services/environmental-consultancy': {
      icon: <Talk />,
      url: 'https://bio-service.pl/pl/uslugi/uslugi-doradcze'
    },
    'services/catalysts-purchase': {
      icon: <Catalyst />,
      url: 'https://bio-service.pl/pl/uslugi/skup-katalizatorow'
    },
    'services/hazardous-substance-packaging': {
      icon: <HazardousSubstance />,
      url: 'https://bio-service.pl/pl/uslugi/opakowania-po-substancjach-niebezpiecznych'
    },
    'services/workshop-waste': {
      icon: <WorkshopWaste />,
      url: 'https://bio-service.pl/pl/uslugi/odpady-warsztatowe'
    }
  }

  const getIconUrl = function() {
    const item = nodeDetails[node.urlCustom]
    if (item === undefined) return;
    return item.icon !== undefined ? item.icon : <CMSIcon iconHtml={node.teaser}/>;
  }

  const getOnClickUrl = function() {
    const item = nodeDetails[node.urlCustom]
    return item != undefined ? item.url : ''
  }

  const redirect = function() {
    const url = getOnClickUrl(node)
    if (url == undefined) return;
    window.open(url, "_blank", "noreferrer");
  }

  return (
    <div className="span4" style={index + 1 % 4 == 0 ? {marginLeft: 0} : {}}>
      <div display="flex" className="sc-bdVaJa sc-bwzfXH sc-bXGyLb fjitEt" onClick={redirect}>
        <div className="sc-bdVaJa fPLHJE">
          {getIconUrl()}
        </div>
        <div
          color="inherit"
          display="flex"
          className="sc-bdVaJa sc-bwzfXH heALEI"
        >
          <h3
            color="inherit"
            fontFamily="'Inter', 'Open sans'"
            fontSize={18}
            fontWeight={700}
            fontStyle="normal"
            className="sc-bdVaJa sc-bZQynM sc-gojNiO dxuXkW"
          >
            {node.title}

          </h3>

          <svg style={{ height: 16 }} viewBox="0 0 61 101" width="2" height="4" class="sc-gzVnrw cHsMJu"><path d="M13.8 2.25C10.54 -0.84 5.43 -0.75 2.25 2.51C-0.84 5.78 -0.75 10.89 2.52 14.07C5.04 16.49 17.68 28.6 40.43 50.4C17.68 72.14 5.04 84.22 2.52 86.64C-0.75 89.73 -0.84 94.93 2.25 98.19C3.84 99.78 5.87 100.75 8.16 100.75C10.28 100.75 12.3 99.96 13.8 98.46C18.21 94.24 53.49 60.52 57.89 56.31C59.48 54.81 60.45 52.6 60.45 50.4C60.45 48.19 59.48 45.9 57.89 44.4C49.08 35.97 18.21 6.46 13.8 2.25Z"></path></svg>

        </div>
        <div
          color="inherit"
          fontFamily="'Inter', 'Open sans'"
          fontSize="14,16"
          fontWeight={400}
          fontStyle="normal"
          className="sc-bdVaJa sc-bZQynM sc-daURTG SUjhS"
        >
          <div
            className="raw_components--panel--3IcXg inspect_panels--inspectionPanel--3Wboz"
            tabIndex={-1}
          >
            <div>
              {ReactHtmlParser(node.content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardsWrapper = function({ nodes }) {
  return nodes.map(function(node, index) {
    return <Card node={node} index={index}/>
  })
}

const AboutUs = function() {
	const { loading, error, data } = useQuery(WASTE_TYPES_CARDS_QUERY, {
    variables: {
      id: 'D5LD',
      lng: "pl",
      siteId: 'wK',
    }
  });

	if (loading) return <></>;
  if (error) return <></>;

  console.log(data);

	return (

    <div className="sc-bdVaJa sc-ifAKCX kinLVY">
      <br></br>
      <h1
          color="primary"
          fontFamily="'Inter', 'Open sans'"
          fontSize="28,40,40"
          fontWeight={800}
          fontStyle="normal"
          className="sc-bdVaJa sc-bZQynM gShXJO"
        >
          Bio Service - Odbiór odpadów warsztatowych
        </h1>
      <div className="sc-bdVaJa sc-bxivhb fFwZTO sc-fBuWsC gPRYTY">
        <div className="">
          <div className="row">
            <div className="span6">
              <h2 className="fyZUtE" style={{ color: '#000000', fontSize: '32px', fontWeight: 700 }}>
                {data.cms.articleContent.title}
              </h2>
            </div>
            <div className="span6">
              <p style={{ color: 'gray', fontSize: '16px' }}>
                {ReactHtmlParser(data.cms.articleContent.content)}
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <div class="">
          <div class="row">
            <CardsWrapper nodes={data.cms.articleContent.children.articles.nodes} />
          </div>
        </div>
        <div display="block,none,none" className="sc-bdVaJa dzTpUK">
          <div
            type="secondary"
            height="1px"
            width="90px"
            className="sc-dnqmqq ftjXMI"
          />
        </div>
      </div>
    </div>
  )
}

export default function AboutUsWrapper() {
	return <ApolloProvider client={client}>
		<AboutUs></AboutUs>
	</ApolloProvider>
}
