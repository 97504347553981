import React from 'react';

class CheckoutComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            checkedItems: [],

            response: {
                title: '',
                content: ''
            },

            validation_message: ''
        };
        this.submitOrder = this.submitOrder.bind(this);
    }

    componentDidMount () {
        window.addEventListener('checkedListChanged', (e) => {this.listChangedHandler(e)});
    }

    componentWillUnmount () {
        window.removeEventListener('checkedListChanged', (e) => {this.listChangedHandler(e)});
    }

    listChangedHandler (e) {
        const listItems = e.target.localStorage.getItem('checkedItems');
        if (listItems !== undefined) {
            this.setState({
                checkedItems: JSON.parse(listItems)
            });
        }
    }

    calculateTotal () {
        // calculate total price
        return this.state.checkedItems.reduce((acc, item) => {
            const ao_length = item.additional_options.length === 0 ? 1 : item.additional_options.length;
            return (acc + item.price * ao_length);
        }, 0);
    }

    isLastOption (option, item) {
       // check if additional option is last in array
       return (item.additional_options.indexOf(option) === item.additional_options.length - 1);
    }

    validateBeforeSubmit () {

        if (this.state.checkedItems.length === 0) {
            const message = 'Proszę wybrać przynajmniej jeden element';
            location.href = "/services?content=" + message + "&title=" + "Błąd walidacji" + "&redirect=false";
            return false;
        }

        return true;
    }

    submitOrder () {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const serviceId = document.querySelector('div[name="service-id"]').getAttribute('data-service-id');
        const serviceBody = {
            items_attributes: {},
            additional_options_attributes: {}
        }
        var ao_index = 0;
        this.state.checkedItems.map(function(item, index) {
            serviceBody.items_attributes[index] = {id: item.id};
            item.additional_options.map((option) => {
                serviceBody.additional_options_attributes[ao_index] = {id: parseInt(option.id)}
                ao_index++;
            });
        });
        const requestBody = {
            service: {
                id: parseInt(serviceId),
                ...serviceBody
            }
        };
        const isValidate = this.validateBeforeSubmit(requestBody);
        if (isValidate) {
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                 },
                body: JSON.stringify(requestBody),
    
            };
            fetch('/services/submit', requestOptions).then((response) => {
                const responseJson = response.json();
                return responseJson;
            }).then((res) => {
                location.href = '/?content=' + res.content + '&title=' + res.title + '&redirect=true';
            }).catch((error) => {
                location.href = '/?content=' + error + '&title=' + "Błąd" + '&redirect=true';
            });
            return true;
        }
        return false;
    }

    render () {
        return (
            <div>
                <h3 className='center'>Podsumowanie zamówienia</h3>
                <br></br>
                <div className='content checkout'>
                    <table className='checkout-table' style={{width:'100%',height:'20px',marginLeft:'10px'}}>
                        <thead></thead>
                        <tbody>
                            {(() => {
                                if (this.state.checkedItems.length > 0) {
                                    return (
                                        this.state.checkedItems.map((item, index) => ( 
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        {item.title}
                                                        <br></br>
                                                        {item.additional_options.length > 0 ? (
                                                            <React.Fragment>
                                                                <div className='details-content'></div>
                                                                {item.additional_options.map((option) => (
                                                                    option.value + `${this.isLastOption(option, item) ? '' : ', '}`)
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                        
                                                    </div>
                                                    <br></br>
                                                    <div className='checkout-item-divider'></div>
                                                </td>
                                            </tr>
                                        ))
                                    );
                                } else {
                                    return (
                                        <tr>
                                            <td><p>Brak usług w koszyku.</p></td>
                                        </tr>
                                    )
                                }
                            })()}
                        </tbody>
                        
                    </table>
                </div>
                <br></br>
                <div className='span checkout-summary'>
                    <h3>
                        Razem:
                        <span className='pull-right'>
                        {this.calculateTotal().toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} zł
                        </span>
                    </h3>
                    <hr style={{border: 'none',borderBottom: '3px solid #ccc'}}></hr>
                </div>
                <div className='center'>
                    <button className='btn3 btn-large btn-success checkout-summary-button' onClick={this.submitOrder}>
                        Zamawiam
                    </button>
                </div>
            </div>
        );
    }

}

export default CheckoutComponent;