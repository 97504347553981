import React from 'react';
import PropTypes from 'prop-types';

import ListComponent from './ListComponent';
import CheckoutComponent from './CheckoutComponent';

class ViewComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            validation_message: '',
        };
    }

    render () {
        return (
            <div className='padding-10 row-fluid'>
                <div className='span9 border-right3'>
                    <ListComponent items={this.props.items}></ListComponent>
                </div>
                <div className='span3' style={{marginLeft: '10px'}}>
                    <CheckoutComponent></CheckoutComponent>
                </div>
            </div>
        );
    }


}

ViewComponent.propTypes = {
    items: PropTypes.arrayOf (

        PropTypes.shape ({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,

            description: PropTypes.string.isRequired,
            unit: PropTypes.string.isRequired,
            orientation: PropTypes.string.isRequired,

            additional_options: PropTypes.arrayOf (
                PropTypes.shape ({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                })
            )
            
        })

    )
}

export default ViewComponent;