import React from "react";
import PropTypes from "prop-types";

class ClientExistsStepComponent extends React.Component {

  constructor() {
    super();
  }

  render() {
    return (
        <React.Fragment>
            <div className="span12 not-available-vertical" style={{lineHeight: 'normal'}}>
                <i className="fa fa-exclamation-triangle fa-3x"></i>Prośba o nadanie uprawnień została przekazana
            </div>
            <div className="span12">
                <div className="alert alert-info"><b>Twoje konto figuruje w naszej bazie, ale nie posiadasz uprawnień do aplikacji Bio Service. Prośba o nadanie uprawnień została przekazana. </b><br></br><br></br>Po otrzymaniu zwrotnego maila z informacją o nadaniu uprawnień, przejdź do logowania. <br/>Zespół Bio Service</div>
            </div>
        </React.Fragment>
    );
  }
}

ClientExistsStepComponent.propTypes = {
    notifyEmployeesUrl: PropTypes.string
};

export default ClientExistsStepComponent;
