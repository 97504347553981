import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser'; 

class ListComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            items: props.items,
            checkedItems: [],
        };
    }

    componentDidMount() {
        window.onbeforeunload = function() {
            localStorage.removeItem('checkedItems');
        };
    }

    saveItems () {
        localStorage.setItem('checkedItems', JSON.stringify(this.state.checkedItems));
        const event = new Event('checkedListChanged');
        window.dispatchEvent(event);
    }

    interpreterOrientation (orientation_int) {
        return (orientation_int === '0') ? 'inline' : '';
    }

    handleCheckItem (item, e) {
        let listItems = this.state.checkedItems.find(x => x.id === item.id);

        if (listItems) {
            if (e.target.checked) {
                listItems.additional_options.push({
                    id: e.target.getAttribute("data-id"),
                    value: e.target.value
                });
                this.saveItems();
                return;
            }

            listItems.additional_options = listItems.additional_options.filter(x => x.value !== e.target.value);
            if (listItems.additional_options.length === 0) {
                this.state.checkedItems = this.state.checkedItems.filter(x => x.id !== item.id);
            }
            this.saveItems();
            return;
        }

        if (e.target.checked) {
            if (item.additional_options.length === 0) {
                this.state.checkedItems.push({
                    id: item.id,
                    title: item.title,
                    price: item.price,
                    additional_options: []
                });
            } else {
                this.state.checkedItems.push({
                    id: item.id,
                    title: item.title,
                    price: item.price,
                    additional_options: [{
                        id: e.target.getAttribute("data-id"),
                        value: e.target.value,
                    }]
                });
            }

            
            this.saveItems();
            return;
        }
    }

    itemWithoutAdditionalOptions (item) {
        if (item.additional_options.length === 0) {
            return true;
        }
        return false;
    }

    render () {
        return (
            <table className="services">
                <thead></thead>
                <tbody>
                    {this.state.items.map((item, index) => (
                            <React.Fragment>
                                <tr key={index}>
                                    <td>
                                        <table style={{tableLayout: 'fixed'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    <details>
                                                        <summary>
                                                            <span style={{display: 'inline-block'}}><h3 style={{margin: 0, marginTop: -20}}>{item.title}</h3></span>
                                                        </summary>
                                                        {!!item.description.length ? (
                                                            <div className="details-content">
                                                                <div className='span8'>
                                                                    <p>{ReactHtmlParser(item.description)}</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                    </details>
                                                    </td>
                                                    <td style={{width: '5%'}}>
                                                        {this.itemWithoutAdditionalOptions(item) ? (
                                                            <span>
                                                                <input type="checkbox" style={{zoom: 1.5, marginTop: 0}} data-id={item.id} onChange={ e => this.handleCheckItem(item, e)}/>  
                                                            </span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </td>
                                                    <td style={{width: '23%'}}>
                                                        <span className="pull-right item-price">
                                                            <h3>
                                                                {item.price.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})} zł
                                                                <br></br>
                                                                <small className="pull-right">brutto {item.unit ? '/':''} {item.unit}</small>
                                                            </h3>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {item.additional_options.length > 0 ? (
                                            <div className='span8'>
                                                <ol className='additional-options-list'>
                                                    {item.additional_options.map((option, index) => (
                                                        <li className={this.interpreterOrientation(item.orientation)} key={index}>
                                                            <label style={{margin: 0}} className={'checkbox ' + this.interpreterOrientation(item.orientation) }>
                                                                <input type="checkbox" style={{zoom: 1.26, marginTop:3}} data-id={option.id} name={option.name} value={option.name} onChange={ e => this.handleCheckItem(item, e)}/>
                                                                <p className='ao-checkbox-label'>{option.name}</p>
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='datails-content'>
                                            <ol className='attachments-list'>
                                                {item.attachments.map((attachment, index) => (
                                                    <li key={index}>
                                                        <a href={"/services/attachments/"+attachment.id+"/download"} target="_blank"><i className="fa fa-download"></i> {attachment.name}.pdf</a>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                        
                                    </td>
                                </tr>
                            </React.Fragment>
                    ))}
                </tbody>
            </table>
        );
    }
}

ListComponent.propTypes = {
    items: PropTypes.arrayOf (

        PropTypes.shape ({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,

            description: PropTypes.string.isRequired,
            unit: PropTypes.string.isRequired,
            orientation: PropTypes.string.isRequired,

            additional_options: PropTypes.arrayOf (
                PropTypes.shape ({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                })
            ),

            attachments: PropTypes.arrayOf (
                PropTypes.shape ({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                })
            )
        })

    )
}

export default ListComponent;