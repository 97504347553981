import React from "react";

const CMSIcon = function({ iconHtml }) {
	return (
		<div
			className="sc-bdVaJa fPLHJE cms-icon"
			style={{width: 52, height: 52}}
			dangerouslySetInnerHTML={{ __html: iconHtml
		}}>
		</div>
	)
}

export default CMSIcon;
