import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';

class BdoStepComponent extends React.Component {

  constructor() {
    super();
    this.state = {
      filled: false,
      monitoredFields: ['user_bdo_app_id', 'user_bdo_app_secret', 'user_bdo'],
      errorMessage: {},
    };
  }

  componentDidMount = () => {
    window.addEventListener('afterBdoDataFilled', () => { this.handleChangeInput() });
    setTimeout(function() { this.handleChangeInput(); }.bind(this), 100);
  }

  renderButton = () => {
    return (
      <button type="button" onClick={this.handleContinueClick} className="btn3 btn-dark pull-right">
        Kontynuuj
      </button>
    );
  };

  handleChangeInput = () => {
    const values = this.state.monitoredFields.map(e => {
      return !!document.getElementById(e).value
    })

    const someTrue = Object.values(values).some(
      value => value === true
    );
    this.setState({ filled: someTrue })
  };

  handleContinueClick = () => {
    const value = document.getElementById('user_bdo').value;

    if (!value) {
      const error = { name: 'user_bdo', message: 'Nie może być puste' }
      this.setState({ errorMessage: error })
    } else {
      this.setState({ errorMessage: {} })
      this.props.goToStep(this.props.totalSteps - 1)
    }
  }

  render() {
    const renderErrorMessage = (name) => {
      if (name === this.state.errorMessage.name)
        return (
          <div className="text-danger">{this.state.errorMessage.message}</div>
        )
    }
    return (
      <React.Fragment>
        <h2>Połączenie z systemem BDO</h2>
        <div className="text-danger" id="bdo_test_connection_errors"></div>
        <p>
          <b>
            Połączenie z systemem BDO umożliwia automatyzację generowania KPO. Zajrzyj do Instrukcji poniżej
          </b>
        </p>
        <p>
          Aby uzupełnić pola poniżej przejdź do konta w BDO { ReactHtmlParser(this.props.instructionHtml) }
        </p>
        <br></br>
        <div className="control-group string optional user_bdo_app_name">
          <label className="string optional control-label" htmlFor="user_bdo_app_name">
            Nazwa aplikacji
          </label>
          <div className="controls">
            <input className="string optional" onChange={this.handleChangeInput} type="text" name="user[bdo_app_name]" id="user_bdo_app_name" />
          </div>
        </div>
        <div className="control-group string optional user_bdo_app_id">
          <label className="string optional control-label" htmlFor="user_bdo_app_id">
            Client ID
          </label>
          <div className="controls">
            <input className="string optional" type="text" onChange={this.handleChangeInput} name="user[bdo_app_id]" id="user_bdo_app_id"></input>
            { renderErrorMessage("user_bdo_app_id") }
            <div className="text-danger" id="bdo_app_secret_id"></div>
          </div>
        </div>
        <div className="control-group string optional user_bdo_app_secret">
          <label className="string optional control-label" htmlFor="user_bdo_app_secret">
            Client Secret
          </label>
          <div className="controls">
            <input className="string optional" type="text" onChange={this.handleChangeInput} name="user[bdo_app_secret]" id="user_bdo_app_secret"></input>
            { renderErrorMessage("user_bdo_app_secret") }
            <div className="text-danger" id="bdo_app_secret_errors"></div>
          </div>
        </div>
        <hr></hr>
        <div className="control-group string optional user_bdo" style={{borderStyle: 'solid', padding: 15, borderWidth: 2}}>
          <label className="string optional control-label" htmlFor="user_bdo">
            <strong>Numer Rejestrowy do Bazy Danych o Odpadach (BDO)</strong>
          </label>
          <div className="controls">
            <input className="string optional" type="text" name="user[bdo]" id="user_bdo"/>
            { renderErrorMessage("user_bdo") }
            <div className="text-danger" id="bdo_errors"></div>
          </div>
        </div>

        <button type="button" onClick={this.props.previousStep} className="btn3 btn-light">
          Powrót
        </button>
        {this.renderButton()}
      </React.Fragment>
    );
  }
}

BdoStepComponent.propTypes = {
  instructionHtml: PropTypes.string.isRequired,
};

export default BdoStepComponent;
