import React from "react";

class BDOQuestionStep extends React.Component {

  constructor() {
    super();
  }

	handleYes = () => {
		this.props.goToNamedStep('bdo');
		sessionStorage.setItem("isBDOStepSkipped", false);
	}

	handleNo = () => {
		this.props.goToNamedStep('wasteAddress')
		sessionStorage.setItem("isBDOStepSkipped", true);
	}

  render() {
    return (
			<React.Fragment>
				<div className="span12 not-available-vertical" style={{lineHeight: 'normal'}}>
					Czy posiadasz numer BDO?
				</div>

				<button type="button" onClick={this.props.previousStep} className="btn3 btn-light">
          Powrót
        </button>

        <button type="button" onClick={this.handleYes} className="btn3 btn-success pull-right" style={{marginLeft: 10}}>
          Tak
        </button>
				&nbsp;
				<button type="button" onClick={this.handleNo} className="btn3 btn-light pull-right" >
          Nie
        </button>
			</React.Fragment>
    );
  }
}

export default BDOQuestionStep;
