import React from "react";

const CarBattery = function() {
	return (
		<svg width="64" height="64" viewBox="0 0 64 52" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M63.0625 15.565C63.5802 15.565 64 15.1453 64 14.6275V7.12746C64 6.60959 63.5802 6.18996 63.0625 6.18996H61.0625V2.27284C61.0625 1.27346 60.2494 0.460336 59.25 0.460336H53.5C52.5006 0.460336 51.6875 1.27346 51.6875 2.27284V6.18996H47.9375V2.27246C47.9375 1.27309 47.1244 0.459961 46.125 0.459961H41.7487C41.231 0.459961 40.8112 0.879586 40.8112 1.39746C40.8112 1.91534 41.231 2.33496 41.7487 2.33496H46.0625V6.18996H17.9375V2.33496H37.6625C38.1802 2.33496 38.6 1.91534 38.6 1.39746C38.6 0.879586 38.1802 0.459961 37.6625 0.459961H17.875C16.8756 0.459961 16.0625 1.27309 16.0625 2.27246V6.18996H12.3125V2.27284C12.3125 1.27346 11.4994 0.460336 10.5 0.460336H4.75C3.75063 0.460336 2.9375 1.27346 2.9375 2.27284V6.18996H0.9375C0.41975 6.18996 0 6.60959 0 7.12746V14.6275C0 15.1453 0.41975 15.565 0.9375 15.565H2.9375V42.165H0.9375C0.41975 42.165 0 42.5846 0 43.1025V50.6025C0 51.1203 0.41975 51.54 0.9375 51.54H63.0625C63.5802 51.54 64 51.1203 64 50.6025V43.1025C64 42.5846 63.5802 42.165 63.0625 42.165H61.0625V15.565H63.0625ZM53.5625 2.33534H59.1875V6.18946H53.5625V2.33534ZM4.8125 2.33534H10.4375V6.18946H4.8125V2.33534ZM59.1875 42.165H52.4087C51.891 42.165 51.4712 42.5846 51.4712 43.1025C51.4712 43.6203 51.891 44.04 52.4087 44.04H62.125V49.665H1.875V44.04H48.3225C48.8402 44.04 49.26 43.6203 49.26 43.1025C49.26 42.5846 48.8402 42.165 48.3225 42.165H4.8125V15.565H9.3325C9.85025 15.565 10.27 15.1453 10.27 14.6275C10.27 14.1096 9.85025 13.69 9.3325 13.69H1.875V8.06496H62.125V13.69H13.4175C12.8997 13.69 12.48 14.1096 12.48 14.6275C12.48 15.1453 12.8997 15.565 13.4175 15.565H59.1875V42.165Z" fill="black"/>
			<path d="M39.4132 25.5205H32.9375V19.1563C32.9375 18.7365 32.6582 18.3677 32.254 18.2538C31.8501 18.1402 31.4192 18.309 31.2001 18.6672L23.7869 30.7902C23.6099 31.0796 23.6031 31.442 23.7689 31.7378C23.9347 32.0336 24.2475 32.2168 24.5866 32.2168H31.0625V38.5731C31.0625 38.993 31.3416 39.3617 31.7457 39.4755C31.8301 39.4992 31.9155 39.5107 32 39.5107C32.3204 39.5107 32.6262 39.3458 32.7996 39.0623L40.2129 26.9473C40.39 26.658 40.3969 26.2955 40.231 25.9996C40.0651 25.7037 39.7525 25.5205 39.4132 25.5205ZM32.9375 35.2448V31.2793C32.9375 30.7615 32.5177 30.3418 32 30.3418H26.2589L31.0625 22.4865V26.4578C31.0625 26.9757 31.4822 27.3953 32 27.3953H37.7405L32.9375 35.2448Z" fill="#005CAA"/>
			<path d="M14.4791 27.9271H12.3334V25.7813C12.3334 25.2634 11.9136 24.8438 11.3959 24.8438C10.8781 24.8438 10.4584 25.2634 10.4584 25.7813V27.9271H8.3125C7.79475 27.9271 7.375 28.3468 7.375 28.8646C7.375 29.3825 7.79475 29.8021 8.3125 29.8021H10.4584V31.948C10.4584 32.4659 10.8781 32.8855 11.3959 32.8855C11.9136 32.8855 12.3334 32.4659 12.3334 31.948V29.8021H14.4792C14.997 29.8021 15.4167 29.3825 15.4167 28.8646C15.4167 28.3468 14.997 27.9271 14.4791 27.9271Z" fill="#005CAA"/>
			<path d="M55.6875 27.9277H49.5209C49.0031 27.9277 48.5834 28.3474 48.5834 28.8652C48.5834 29.3831 49.0031 29.8027 49.5209 29.8027H55.6875C56.2052 29.8027 56.625 29.3831 56.625 28.8652C56.625 28.3474 56.2052 27.9277 55.6875 27.9277Z" fill="#005CAA"/>
		</svg>
	)
}

export default CarBattery;
