import React from "react";
import validator from 'validator';

class FirstStepComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: {},
    };
  }

  handleContinueClick = (e) => {
    const email = document.getElementById('user_email').value
    if (!validator.isEmail(email)) {
      this.setState({ errorMessages: {
          name: "email",
          message: "Wprowadzono niepoprawny adres"
        }
      });
      e.preventDefault();
      return;
    }
    this.props.nextStep();
  }

  render() {
    const renderErrorMessage = (name) => {
      if (name === this.state.errorMessages.name)P
        return (
          <div className="text-danger">{this.state.errorMessages.message}</div>
        )
    }
    return (
      <React.Fragment>
        <div className="control-group email required user_email">
          <label className="email required control-label" htmlFor="user_email">
            <abbr title="required">*</abbr> E-mail
          </label>
          <div className="controls">
            <input className="string email required" type="email" name="user[email]" id="user_email"/>
            { renderErrorMessage("email") }
            <div className="text-danger" id="email_errors"></div>
          </div>
        </div>
        <button type="button" onClick={this.handleContinueClick} className="btn3 btn-dark pull-right">
          Kontynuuj
        </button>
      </React.Fragment>
    );
  }
}

export default FirstStepComponent;
