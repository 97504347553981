import React from "react"
import PropTypes from "prop-types"

class LoginComponent extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      isExternal: true,
      canAskForAccess: false,
      continue: false,
      errorMessages: {},
    };
  };

  componentDidMount() {
    const email = document.getElementById("user_email").value
    if (this.isValidEmail(email)) {
      this.setState({ isExternal: !this.ableToLoginWithSSO(value) });
    }
  }

  isValidEmail = (email) => {
    return /\S+@\S+\.[a-zA-Z]{2,}$/.test(email);
  };

  handleEmailChange = (event) => {
    const value = event.target.value;
    if (this.isValidEmail(value))
      this.setState({ isExternal: !this.ableToLoginWithSSO(value) });
    if (!value) {
      this.setState({ isExternal: true });
      this.setState({ continue: false });
    }
  };

  handleClickContinue = () => {
    const value = document.getElementById("user_email").value;

    if (this.isValidEmail(value)) {
      this.setState({ continue: !this.state.continue });
      this.setState({ errorMessages: { } });

      if (this.state.canAskForAccess) {
        this.setState({ continue: false });
        this.setState({ errorMessages: { } });
      }

    } else {
      this.setState({ errorMessages: {
          name: "email",
          message: "Wprowadzono niepoprawny adres"}
      });
    }
  };

  handleRedirectToSSO = () => {
    window.location.href = this.props.sso_redirection_path;
  }

  ableToLoginWithSSO = (email) => {
    $('#main-content').addClass('loading');
    this.setState({ errorMessages: { } });
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.props.authenticity_token
    }
    fetch(this.props.external_user_path, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        email: email
      })
    })
    .then((response) => response.json())
    .then((data) => {
      this.setState(data);
      $('#main-content').removeClass('loading');
    })
    .catch(() => {
      this.setState({
        isExternal: true,
        canAskForAccess: false
      });
      $('#main-content').removeClass('loading');
    });
  }

  render () {
    const renderErrorMessage = (name) => {
      if (name === this.state.errorMessages.name)
        return (
          <div className="text-danger">{this.state.errorMessages.message}</div>
        )
    }
    return (

      <React.Fragment>
        {this.state.canAskForAccess ? (
            <React.Fragment>
              <div className="alert alert-danger" role="alert">Twoje konto nie posiada dostępu do aplikacji BioService, ale przekazaliśmy prośbę o nadanie uprawnień do naszego działu.</div>
            </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <form className="simple_form span8 offset2" id="login_form" noValidate="novalidate" action={this.props.login_path} acceptCharset="UTF-8" method="post">
          <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} autoComplete="off"/>
          <div className="control-group email required user_email">
              <label className="email required control-label" htmlFor="user_email"><abbr title="required">*</abbr> E-mail</label>
              <div className="controls">
                <input className="string email required form-invalid" type="email" onChange={this.handleEmailChange} name="user[email]" id="user_email"/>
                {renderErrorMessage("email")}
              </div>
          </div>
          {this.state.isExternal ? (
              <React.Fragment>
                  {!this.state.continue ? (
                    <React.Fragment>
                      <input name="commit" value="Kontynuuj" onClick={this.handleClickContinue} className="btn3 btn-block" id="login" data-disable-with="Zaloguj się"/>
                      <div className="text-center">lub</div>
                      <a className="btn3 btn-block btn-success" href={this.props.register_path}>Zarejestruj się</a>
                    </React.Fragment>
                  ): (
                    <React.Fragment>
                      <p className="clearfix"></p>
                      <div className="control-group password required user_password">
                          <label className="password required control-label" htmlFor="user_password"><abbr title="required">*</abbr> Hasło</label>
                          <div className="controls">
                              <input className="password required" type="password" name="user[password]" id="user_password"/>
                          </div>
                      </div>
                      <div className="controls">
                          <label className="checkbox">
                          <input name="user[remember_me]" type="hidden" value="0" autoComplete="off"/>
                          <input type="checkbox" value="1" name="user[remember_me]" id="user_remember_me"/> Zapamiętaj mnie
                          <a className="pull-right" href={this.props.password_forget_path}>Zapomniałeś hasło?</a>
                        </label>
                      </div>
                      <input type="submit" name="commit" value="Zaloguj się" className="btn3 btn-block btn-login-classic" id="login" data-disable-with="Zaloguj się"/>
                    </React.Fragment>
                  )}

              </React.Fragment>
          ) : (
              <React.Fragment>
                <input name="commit" value="Zaloguj się przez SSO" onClick={this.handleRedirectToSSO} className="btn3 btn-block btn-login-sso" id="login" data-disable-with="Zaloguj się"/>
              </React.Fragment>
          )}
        </form>
      </React.Fragment>
    );
  }
}

LoginComponent.propTypes = {
  authenticity_token: PropTypes.string.isRequired,

  login_path: PropTypes.string.isRequired,
  register_path: PropTypes.string.isRequired,
  password_forget_path: PropTypes.string.isRequired,
  external_user_path: PropTypes.string.isRequired,
  sso_redirection_path: PropTypes.string.isRequired
}

export default LoginComponent;
