import React from "react";

const HazardousSubstance = function() {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M50.375 34.9912H43.8816C42.9852 34.9912 42.2586 34.2646 42.2586 33.3682V31.8862C42.2586 30.9898 42.9852 30.2632 43.8816 30.2632H50.375V34.9912Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M50.375 44.6694H43.8816C42.9852 44.6694 42.2586 43.9428 42.2586 43.0464V41.5644C42.2586 40.668 42.9852 39.9414 43.8816 39.9414H50.375V44.6694Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M50.375 54.3471H43.8816C42.9852 54.3471 42.2586 53.6205 42.2586 52.7241V51.2421C42.2586 50.3458 42.9852 49.6191 43.8816 49.6191H50.375V54.3471Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M21.7916 63.0625H17.3483C15.2919 63.0625 13.6249 61.4005 13.6249 59.3502V12.1766C13.6249 11.5218 14.1573 10.9912 14.8139 10.9912H31.7529C32.0543 10.9912 32.3513 11.0633 32.6189 11.2017L36.3966 13.1538" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M40.304 15.1729L42.865 16.4962C47.4787 18.8804 50.375 23.6295 50.375 28.8106V59.3503C50.375 61.4006 48.708 63.0626 46.6516 63.0626H26.2084" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M31.47 10.991H15.5694V7.593C15.5694 7.3575 15.7604 7.1665 15.9959 7.1665H31.0435C31.279 7.1665 31.47 7.3575 31.47 7.593V10.991Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M29.8755 7.16675H16.7921V2.59375C16.7921 1.679 17.5336 0.9375 18.4484 0.9375H28.2192C29.134 0.9375 29.8755 1.679 29.8755 2.59375V7.16675Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M30.3885 56.7915H13.625V20.5278H30.3885C31.6873 20.5278 32.7401 21.5807 32.7401 22.8795V54.4398C32.7401 55.7386 31.6873 56.7915 30.3885 56.7915Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M22.7021 34.418C25.1046 34.418 27.0522 32.4704 27.0522 30.0679C27.0522 27.6654 25.1046 25.7178 22.7021 25.7178C20.2996 25.7178 18.352 27.6654 18.352 30.0679C18.352 32.4704 20.2996 34.418 22.7021 34.418Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18.7766 39.9683H23.1232" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18.7766 43.6138H27.5886" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18.7766 47.2593H27.5886" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M18.7766 50.9053H25.2919" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	)
}

export default HazardousSubstance;
