import React from "react";
import PropTypes from "prop-types";
import StepWizard from "react-step-wizard";

import FirstStepComponent from "./Steps/FirstStepComponent";
import BasicStepComponent from "./Steps/BasicStepComponent";
import BdoStepComponent from "./Steps/BdoStepComponent";
import CompanyAddressStepComponent from "./Steps/CompanyAddressStepComponent";
import RulesStepComponent from "./Steps/RulesStepComponent";
import WasteAddressStepComponent from "./Steps/WasteAddressStepComponent";
import ClientExistsStepComponent from "./Steps/ClientExistsStepComponent";
import BDOQuestionStep from "./Steps/BDOQuestionStep";

class RegisterComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstStep: ''
    }
    this.values = JSON.parse(this.props.values);
    this.validationErrors = JSON.parse(this.props.errors);
  }

  componentDidMount = () => {
    this.fillValuesAfterValidation();
    this.showValidationErrors();
  }

  getStepWithErrors = () => {
    const componentsOrder = {
      email: 1,
      name: 2,
      phone: 2,
      kh_code: 2,
      bdo: 2,
      bdo_test_connection: 2
    }
    for (const key in componentsOrder) {
      const error = this.validationErrors[key];
      if (error) return componentsOrder[key];
    }
    return 1;
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  }

  showValidationErrors = () => {
    if (Object.keys(this.validationErrors).length === 0) {
      return;
    }

    Object.keys(this.validationErrors).map((key) => {

      const element = document.getElementById(key + '_errors');
      if (!element) return;

      this.validationErrors[key].forEach(value => {
        const errorElement = "<p>" + value + "</p>"
        element.innerHTML += errorElement;
      });

    });
  }

  fillValuesAfterValidation = () => {
    if (Object.keys(this.values).length === 0) {
      return;
    }

    Object.keys(this.values).map((key) => {

      var current_key = key;
      const value = this.values[current_key];

      if (current_key == 'user_kh_code') {
        current_key = "registration_kh_code";
      }

      const element = document.getElementById(current_key)
      if (element) {
        element.value = value;
      }

    });

    const listenedKeys = ['user_bdo_app_name', 'user_bdo_app_id', 'user_bdo_app_secret']
    const isBdoFilled = listenedKeys.every(key => key in this.values);

    if (isBdoFilled) {
      const event = new Event('afterBdoDataFilled');
      window.dispatchEvent(event);
    }

  }

  render () {
    return (
      <React.Fragment>
        <form className="simple_form span8 offset2" id="register_form_form" noValidate="" action="/bio/rejestracja" acceptCharset="UTF-8" method="post" onKeyDown={this.handleKeyDown}>
          <input type="hidden" name="authenticity_token" autoComplete="off" value={this.props.authenticityToken}/>
          <StepWizard initialStep={this.getStepWithErrors()}>
            <FirstStepComponent stepName={"first"} hashKey={"first"} firstStep={this.state.firstStep}/>
            <BasicStepComponent
              stepName={"basic"}
              hashKey={"basic"}
              vatNumber={this.values ? this.values.user_vat_number : ''}
              regon={this.values ?  this.values.user_regon : ''}
              phone={this.values ? this.values.user_phone : ''}
              khCode={this.values ? this.values.user_kh_code : ''}
              passwordRequired={this.values ? true : false}
              authenticityToken={this.props.authenticityToken}
              internalCustomerUrl={this.props.internalCustomerUrl}
              businessPartnerUrl={this.props.businessPartnerUrl}
            />
            <CompanyAddressStepComponent
              stepName={"companyAddress"}
              hashKey={"companyAddress"}
              provincesHtml={this.props.provincesHtml}
            />
            <BDOQuestionStep
              stepName={'bdoQuestion'}
              hashKey={'bdoQuestion'}
            />
            <BdoStepComponent
              stepName={"bdo"}
              hashKey={"bdo"}
              instructionHtml={this.props.instructionHtml}
            />
            <WasteAddressStepComponent
              stepName={"wasteAddress"}
              hashKey={"wasteAddress"}
              provincesHtml={this.props.provincesHtml}
            />
            <RulesStepComponent
              stepName={"rules"}
              hashKey={"rules"}
              regulationLink={this.props.regulationLink}
            />
            <ClientExistsStepComponent
              stepName={"clientExists"}
              hashKey={"clientExists"}
            />
          </StepWizard>
        </form>
      </React.Fragment>
    );
  }
}

RegisterComponent.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  provincesHtml: PropTypes.string.isRequired,
  instructionHtml: PropTypes.string.isRequired,
  regulationLink: PropTypes.string.isRequired,
  businessPartnerUrl: PropTypes.string.isRequired,
  internalCustomerUrl: PropTypes.string.isRequired,

  errors: PropTypes.string,

  values: PropTypes.string
};

export default RegisterComponent;
