import React from "react";

const WorkshopWaste = function() {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M61.8152 21.5809H59.3961V17.9146H61.8152C62.5041 17.9146 63.0625 18.4729 63.0625 19.1618V20.3337C63.0625 21.0224 62.5041 21.5809 61.8152 21.5809Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M61.8152 46.0848H59.3961V42.4185H61.8152C62.5041 42.4185 63.0625 42.9768 63.0625 43.6657V44.8376C63.0625 45.5265 62.5041 46.0848 61.8152 46.0848Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M10.8462 42.4189H13.2654V46.0853H10.8462C10.1574 46.0853 9.599 45.5269 9.599 44.8381V43.6662C9.599 42.9773 10.1575 42.4189 10.8462 42.4189Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M10.8462 17.9146H13.2654V21.5809H10.8462C10.1574 21.5809 9.599 21.0226 9.599 20.3337V19.1618C9.599 18.4729 10.1575 17.9146 10.8462 17.9146Z" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M13.2655 33.585H4.08289V37.4911H13.2655V33.585Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M1.44588 38.8243H3.5745C3.85525 38.8243 4.08287 38.5967 4.08287 38.316V32.7603C4.08287 32.4796 3.85525 32.252 3.5745 32.252H1.44588C1.16513 32.252 0.9375 32.4796 0.9375 32.7603V38.316C0.9375 38.5967 1.16513 38.8243 1.44588 38.8243Z" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 19.6636H54.4042" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 23.7759H54.4042" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 27.8882H54.4042" stroke="#005CAA" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 32H54.4042" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 36.1123H54.4042" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 40.2246H54.4042" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M19.2089 44.3364H54.4042" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M13.2654 29.5851V49.0608C13.2654 49.7074 13.7895 50.2316 14.4361 50.2316H18.038C18.6846 50.2316 19.2088 49.7074 19.2088 49.0608V14.9393C19.2088 14.2927 18.6846 13.7686 18.038 13.7686H14.4361C13.7895 13.7686 13.2654 14.2927 13.2654 14.9393V25.9602" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M27.3615 15.6357H19.2089V48.3642H54.4042V15.6357H30.9865" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M59.3962 34.2998V14.8037C59.3962 14.2321 58.9328 13.7686 58.3612 13.7686H55.4394C54.8678 13.7686 54.4044 14.2319 54.4044 14.8037V49.1967C54.4044 49.7683 54.8678 50.2317 55.4394 50.2317H58.3612C58.9328 50.2317 59.3962 49.7683 59.3962 49.1967V37.9248" stroke="black" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	)
}

export default WorkshopWaste;
