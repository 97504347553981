import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';

class WasteAddressStepComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
    };
    this.provincesHtml = this.props.provincesHtml
                .replace('user[headquarter_address_attributes][province_id]',
                  'user[reg_business_address_attributes][province_id]')
                .replace('user_headquarter_address_attributes_province_id',
                  'user_reg_business_address_attributes_province_id')
  }

  handleContinueClick = () => {
    this.state.errorMessages = [];
    this.setState({ errorMessages: [] });

    const toValidate = ['user_reg_business_address_attributes_city',
                        'user_reg_business_address_attributes_address',
                        'user_reg_business_address_attributes_postal_code_id',
                        'user_reg_business_address_attributes_province_id']

    toValidate.map((element_id) => {

      const value = document.getElementById(element_id).value;

      if (!value) {
        const error = { name: element_id, message: "Nie może być puste" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

    });

    if (this.state.errorMessages.length == 0) {
      this.props.nextStep();
    }
  }

  goToPreviousStep = () => {
    const isBDOSkipped = sessionStorage.getItem('isBDOStepSkipped') == 'true';
    isBDOSkipped ? this.props.goToNamedStep('bdoQuestion') : this.props.previousStep()
    window.scrollTo(0, 0);
  }

  handleSameAsAddressClick(e) {
    const isSame = e.target.checked;

    const elements = ['user_headquarter_address_attributes_city',
                      'user_headquarter_address_attributes_address',
                      'user_headquarter_address_attributes_postal_code_id',
                      'user_headquarter_address_attributes_province_id']

    sessionStorage.setItem('sameAsAddress', isSame);

    elements.forEach(eId => {
      const hq_element = document.getElementById(eId);
      const reg_element = document.getElementById(eId.replace('headquarter', 'reg_business'));

      if (isSame) {
        reg_element.value = hq_element.value;
        return;
      }

      reg_element.value = '';
    })
  }

  render() {
    const renderErrorMessage = (name) => {
      const allErrors = this.state.errorMessages;
      const namedError = allErrors.find(error => error.name === name);
      if (namedError !== undefined) {
        return (
          <div className="text-danger">{namedError.message}</div>
        );
      }
    }
    const isBDOSkipped = sessionStorage.getItem('isBDOStepSkipped') == 'true';
    return (
      isBDOSkipped ? (
        <React.Fragment>
          <h2>Miejsce odbioru odpadów</h2>
          <div className="checkbox">
            <input onClick={this.handleSameAsAddressClick} type="checkbox" value="1" id="same_as_address"/>
            <label><strong>Taki sam jak adres firmy</strong></label>
          </div>
          <br></br>
          <div className="control-group string user_reg_business_address_city">
            <label className="string control-label" htmlFor="user_reg_business_address_attributes_city">
              <abbr title="required">*</abbr> Miejscowość
            </label>
            <div className="controls">
              <input className="string required"  type="text" name="user[reg_business_address_attributes][city]" id="user_reg_business_address_attributes_city" />
              { renderErrorMessage('user_reg_business_address_attributes_city') }
            </div>
          </div>
          <div className="control-group string user_reg_business_address_address">
            <label className="string control-label" htmlFor="user_reg_business_address_attributes_address">
              <abbr title="required">*</abbr> Ulica i numer lokalu
            </label>
            <div className="controls">
              <input className="string" type="text" name="user[reg_business_address_attributes][address]" id="user_reg_business_address_attributes_address" />
              { renderErrorMessage('user_reg_business_address_attributes_address') }
            </div>
          </div>
          <div className="control-group string autocomplete user_reg_business_address_postal_code_id">
            <label className="string control-label" htmlFor="user_reg_business_address_attributes_postal_code_id">
              <b><abbr title="required">*</abbr> Kod pocztowy (wybierz z listy)</b>
            </label>
            <div className="controls">
              <input
                className="autocomplete optional form-control text-center postal_code ui-autocomplete-input"
                placeholder="proszę wpisać"
                data-id-element="#autocomplete_selected_postal_code"
                data-autocomplete="/users/autocomplete_postal_code"
                type="text"
                name="user[reg_business_address_attributes][postal_code_id]"
                id="user_reg_business_address_attributes_postal_code_id"
                autoComplete="off"
              />
              { renderErrorMessage('user_reg_business_address_attributes_postal_code_id') }
            </div>
          </div>
          <div className="control-group select user_reg_business_address_province_id">
            <label className="select control-label" htmlFor="user_reg_business_address_attributes_province_id">
              <abbr title="required">*</abbr> Województwo
            </label>
            <div className="controls">
                { ReactHtmlParser(this.provincesHtml) }
                { renderErrorMessage('user_reg_business_address_attributes_province_id') }
            </div>
          </div>

          <button type="button" onClick={this.goToPreviousStep} className="btn3 btn-light">
            Powrót
          </button>
          <button type="button" onClick={this.handleContinueClick} className="btn3 btn-dark pull-right">
            Kontynuuj
          </button>
      </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )
    );
  }
}

WasteAddressStepComponent.propTypes = {
  provincesHtml: PropTypes.string.isRequired
};

export default WasteAddressStepComponent;
