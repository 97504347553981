import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

class EventsComponent extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            showDetails: false,
        }; 
    }

    handleShowDetails = () => {
        this.setState({showDetails: !this.state.showDetails});
        this.setState({lessMore: this.state.showDetails ? this.more : this.less});
    }

    renderButtonInner = () => {
        if (!this.state.showDetails) {
            return (
                <React.Fragment>
                    <i class="fa fa-chevron-down"></i>&nbsp;
                        Pokaż więcej
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <i class="fa fa-chevron-up"></i>&nbsp;
                        Pokaż mniej
                </React.Fragment>
            )
        }
    }

    itemsToDisplay = () => {
        if (this.state.showDetails) {
            return this.props.events;
        }
        return this.props.events.slice(Math.max(this.props.events.length - 4, 0));
    }

    renderMessage = (state_from, state_to) => {
        if (state_from == null && state_to == 'draft') {
            return 'Utworzenie zgłoszenia';
        }
        if (state_from == 'draft' && state_to == 'new') {
            return 'Wysłanie zgłoszenia';
        }
        if (state_from == 'new' && state_to == 'in_progress') {
            return 'Zgłoszenie rozpatrywane';
        }
        if (state_to == 'approved') {
            return 'Zgłoszenie zostało przyjęte';
        }
        if (state_to == 'rejected') {
            return 'Zgłoszenie odrzucone';
        }
    }
    
    render () {
        let items = this.itemsToDisplay();
        return (
            <React.Fragment>
                <h2>
                    Historia zgłoszenia
                    <button name="show-Klient" type="submit" onClick={this.handleShowDetails} class="btn3 btn3-xs btn-bio-success pull-right" data-according="true" data-target="table-Klient">
                        {this.renderButtonInner()}
                    </button>
                </h2>
                <table className='table-striped v2'>
                    <thead>
                    </thead>
                    <tbody>
                        {items.map((event, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {event.user ? !!event.user.company_name ? event.user.company_name : event.user.name : 
                                            <span className="badge badge-success">{event.auxiliary_info}</span>
                                        }
                                    </td>
                                    <td>{event.created_at}</td>
                                    <td>
                                        {event.message ? ReactHtmlParser(event.message) : 
                                            this.renderMessage(event.state_from, event.state_to)
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

EventsComponent.propTypes = {
    events: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            user: PropTypes.shape({
                company_name: PropTypes.string,
            }),
            message: PropTypes.string,
            created_at: PropTypes.string.isRequired,
            state_from: PropTypes.string,
            state_to: PropTypes.string,
            auxiliary_info: PropTypes.string,
        })
    )
};

export default EventsComponent;