import React from "react";

const Catalyst = function() {
	return (
		<svg width="64" height="64" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M83.5475 220.547H34.8555V291.452H83.5475V220.547Z" stroke="black" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M482.341 305.529H500.216C502.582 305.529 504.5 303.611 504.5 301.245V210.754C504.5 208.388 502.582 206.47 500.216 206.47H482.341C479.975 206.47 478.057 208.388 478.057 210.754V301.245C478.057 303.611 479.975 305.529 482.341 305.529Z" stroke="black" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M172.664 165.783V346.217" stroke="#005CAA" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M241.033 165.783V346.217" stroke="#005CAA" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M309.402 165.783V346.217" stroke="#005CAA" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M107.68 251.025V260.975" stroke="#005CAA" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M374.385 251.025V260.975" stroke="#005CAA" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M7.5 294V303.257C7.5 305.299 9.155 306.954 11.197 306.954H31.158C33.2 306.954 34.855 305.299 34.855 303.257V208.743C34.855 206.701 33.2 205.046 31.158 205.046H11.197C9.155 205.046 7.5 206.701 7.5 208.743V265" stroke="black" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M99.5389 193.739L88.8609 201.468C85.5239 203.884 83.5469 207.753 83.5469 211.873V300.128C83.5469 304.248 85.5229 308.118 88.8609 310.533L134.792 343.777C136.983 345.363 139.619 346.217 142.323 346.217H339.743C342.448 346.217 345.083 345.363 347.274 343.777L393.205 310.533C396.542 308.117 398.518 304.248 398.518 300.128V211.873C398.518 207.753 396.542 203.883 393.205 201.468L347.274 168.223C345.083 166.637 342.447 165.783 339.743 165.783H142.323C139.618 165.783 136.983 166.637 134.792 168.223L123.078 176.702" stroke="black" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M454.907 291.012H478.058V220.988H398.52V291.012H425.907" stroke="black" stroke-width="15" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	)
}

export default Catalyst;
