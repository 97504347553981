import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';

class RulesStepComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      submitButton: false,
      interestChecked: false,
      rulesChecked: false
    };
  }

  handleChange = () => {
    this.validateRules();
  }

  validateRules = () => {
    this.setState({ errorMessages: [] });
    const isChecked = document.getElementById('user_terms_and_conditions').checked
    const elements = document.getElementsByName("user[interests][]");
    const atLeastOneChecked = Array.from(elements).some(element => element.checked);

    if (!isChecked) {
      this.setState({ errorMessages: [{
          name: "user_terms_and_conditions",
          message: "To pole jest wymagane"
        }]
      });
      window.scrollTo(0, 0);
      return;
    }
    if (!atLeastOneChecked) {
      this.setState({ errorMessages: [{
          name: "interests[]",
          message: "Prosimy zaznaczyć przynajmniej 1 opcję"
        }]
      });
      window.scrollTo(0, 0);
      return;
    }
    this.setState({ submitButton: true });
  }

  renderButton = () => {
    const submitButton = (
      <input
        type="submit"
        name="commit"
        value="Zarejestruj się"
        className="btn3 btn-success pull-right"
        data-disable-with="Zarejestruj się"
      />
    )
    const validateButton = (
      <button
        value="Zarejestruj się"
        type="button"
        className="btn3 btn-success pull-right validation"
        onClick={this.validateRules}
        data-disable-with="Zarejestruj się"
      >
        Zarejestruj się
      </button>
    )
    return this.state.submitButton ? submitButton : validateButton;
  }

  goToPreviousStep = () => {
    const isBDOSkipped = sessionStorage.getItem('isBDOStepSkipped') == 'true';
    isBDOSkipped ? this.props.previousStep() : this.props.goToNamedStep('bdo')
    window.scrollTo(0, 0);
  }

  render() {
    const renderErrorMessage = (name) => {
      const allErrors = this.state.errorMessages;
      const namedError = allErrors.find(error => error.name === name);
      if (namedError !== undefined) {
        return (
          <div className="text-danger">{namedError.message}</div>
        );
      }
    }
    return (
      <React.Fragment>
        <div className="registration-info">

          <div className="clearfix">
            <b><p><abbr title="required">*</abbr> Jestem zainteresowany:</p></b>
          </div>
          { renderErrorMessage('interests[]') }
          <div className="controls">
            <label className="checkbox">
              <div className="control-group boolean optional battery_steel">
                <label className="boolean optional control-label" htmlFor="battery_steel">
                  odbiorem akumulatorów, żelaza i stali lub opon
                </label>
                <div className="controls" style={{marginTop: '-3px'}}>
                  <label className="checkbox">
                    <input className="boolean optional" type="checkbox" onChange={this.handleChange} value="battery_steel" name="user[interests][]" id="battery_steel" />
                  </label>
                </div>
              </div>
              <div className="control-group boolean optional other_wastes" data-toggle="tooltip" data-placement="top" title="olej, filtry olejowe, filtry powietrza, opakowania po substancjach niebezpiecznych">
                <label className="boolean optional control-label" htmlFor="other_wastes">
                  odbiorem innych odpadów warsztatowych
                </label>
                <div className="controls" style={{marginTop: '-3px'}}>
                  <label className="checkbox">
                    <input className="boolean optional" onChange={this.handleChange} type="checkbox" value="other_wastes" name="user[interests][]" id="other_wastes" />
                  </label>
                </div>
              </div>
              <div className="control-group boolean optional consulting_services">
                <label className="boolean optional control-label" htmlFor="consulting_services">
                  usługami doradczo-środowiskowymi
                </label>
                <div className="controls" style={{marginTop: '-3px'}}>
                  <label className="checkbox">
                    <input className="boolean optional" onChange={this.handleChange} type="checkbox" value="consulting_services" name="user[interests][]" id="consulting_services" />
                  </label>
                </div>
              </div>
            </label>
          </div>
          <div className="clearfix"></div>



          <div className="clearfix">
            <p>Wyrażam zgodę na otrzymywanie od Inter Cars S.A. informacji marketingowych przedstawionych za pośrednictwem:</p>
          </div>
          <div className="controls">
            <label className="checkbox">
              <div className="control-group boolean optional user_rodo_email">
                <label className="boolean optional control-label" htmlFor="user_rodo_email">
                  E-mail
                </label>
                <div className="controls">
                  <input value="0" autoComplete="off" type="hidden" name="user[rodo_email]" />
                  <label className="checkbox">
                    <input className="boolean optional" type="checkbox" value="1" name="user[rodo_email]" id="user_rodo_email" />
                  </label>
                </div>
              </div>
              <div className="control-group boolean optional user_rodo_sms">
                <label className="boolean optional control-label" htmlFor="user_rodo_sms">
                  SMS/MMS
                </label>
                <div className="controls">
                  <input value="0" autoComplete="off" type="hidden" name="user[rodo_sms]" />
                  <label className="checkbox">
                    <input className="boolean optional" type="checkbox" value="1" name="user[rodo_sms]" id="user_rodo_sms" />
                  </label>
                </div>
              </div>
              <div className="control-group boolean optional user_rodo_phone">
                <label className="boolean optional control-label" htmlFor="user_rodo_phone">
                  Telefon
                </label>
                <div className="controls">
                  <input value="0" autoComplete="off" type="hidden" name="user[rodo_phone]" />
                  <label className="checkbox">
                    <input className="boolean optional" type="checkbox" value="1" name="user[rodo_phone]" id="user_rodo_phone" />
                  </label>
                </div>
              </div>
            </label>
          </div>
          <br/>
          <div className="controls accept">
            <label className="checkbox">
              <div className="control-group boolean required user_terms_and_conditions">
                <label className="boolean required control-label" htmlFor="user_terms_and_conditions">
                  <abbr title="required">*</abbr> Akceptuję { ReactHtmlParser(this.props.regulationLink) }
                </label>
                <div className="controls">
                  <input value="0" autoComplete="off" type="hidden" name="user[terms_and_conditions]" />
                  <label className="checkbox">
                    <input className="boolean required" type="checkbox" onChange={this.handleChange} value="1" name="user[terms_and_conditions]" id="user_terms_and_conditions" />
                  </label>
                </div>
              </div>
              { renderErrorMessage('user_terms_and_conditions') }
            </label>
          </div>
          <div className="clearfix"></div>
          <div className="administration" style={{ maxHeight: 400, overflowY: 'scroll' }}>
            <p className="first">
              Administratorem danych osobowych jest Inter Cars S.A., ul. Powsińska 64, 02-903 Warszawa,
              tel. 801 80 20 20, sekretariat@intercars.eu. Administrator wyznaczył Inspektora Ochrony Danych,
              z którym można kontaktować się pod adresem iod@intercars.eu
            </p>
            <p>
              Dane będą przetwarzane w celach realizacji Regulaminu korzystania z Platformy BS BIO i Formularza Zgłoszeniowego, w tym prowadzenia Konta użytkownika i dostępu do Systemu (podstawa prawna: przetwarzanie niezbędne do wykonania umowy, art. 6 ust. 1 lit. b RODO), marketingu (podstawa prawna: prawnie uzasadniony interes administratora, art. 6 ust. 1 lit. f RODO) oraz ustalania i dochodzenia ewentualnych roszczeń (podstawa prawna: prawnie uzasadniony interes administratora, art. 6 ust. 1 lit. f RODO).
            </p>
            <p>Podanie danych jest dobrowolne ale jest niezbędne do zrealizowania ww. celów. W przypadku wybrania opcji autouzupełniania, informacje w zakresie – odpowiednio – nr BDO, nazwy i adresu siedziby zostaną pobrane z Bazy danych o produktach i opakowaniach oraz gospodarce odpadami, której administratorem jest Minister Klimatu i Środowiska. Z informacją tego administratora danych można zapoznać się pod adresem: https://bdo.mos.gov.pl/polityka-prywatnosci/</p>
            <p>
              Pani/Pana dane osobowe w zakresie: daty podpisania i rozwiązania umowy z Operatorem, ilość odpadów odebranych, ewentualne przypadki nieodebrania odpadów oraz zaległości płatniczych powyżej 30 dni, będą pozyskiwane od Operatora (w
              związku z niezbędnością do wykonania umowy).
            </p>
            <p>W stosownych przypadkach odbiorcami danych mogą być podmioty świadczące usługi utylizacji odpadów, banki, kurierzy i Poczta, dostawcy usług np. dostawcy rozwiązań teleinformatycznych.</p>
            <p>Dane będą przechowywane przez okres: przedawnienia roszczeń oraz wymagany właściwymi przepisami prawa (np. przepisami o&nbsp;rachunkowości, ustawy o odpadach).</p>
            <p>
              Każdej osobie przysługuje prawo do żądania dostępu do swoich danych, ich sprostowania, usunięcia, przenoszenia, ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania oraz prawo do wniesienia skargi do Prezesa Urzędu
              Ochrony Danych Osobowych.
            </p>
            <p>Prawo do wniesienia sprzeciwu wobec przetwarzania danych przysługuje w przypadku przetwarzania danych na podstawie prawnie uzasadnionego interesu administratora danych.</p>
          </div>
          <br />
        </div>
        <button type="button" onClick={this.goToPreviousStep} className="btn3 btn-light">
          Powrót
        </button>
        {this.renderButton()}
      </React.Fragment>
    );
  }
}

RulesStepComponent.propTypes = {
  regulationLink: PropTypes.string.isRequired,
};

export default RulesStepComponent;
