import React from "react";
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';

class BasicStepComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      user_vat_number: this.props.vatNumber,
      user_regon: this.props.regon,
      user_phone: this.props.phone,
      registration_kh_code: this.props.khCode,
      passwordRequired: this.props.passwordRequired,
      duringRequest: false,
      manuallyFillData: false,
    };
    this.headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.props.authenticityToken
    }
  }

  handleContinueClick = () => {
    this.state.errorMessages = [];
    this.setState({ errorMessages: [] });

    const toValidate = ['user_vat_number', 'user_name', 'user_company_name',
                        'user_regon', 'user_phone']
    const validateLength = ['user_vat_number', 'user_regon', 'user_phone']

    toValidate.map((element_id) => {

      const value = document.getElementById(element_id).value;
      const requiredLength = (value.split('_').length - 1) + value.replaceAll('_', '').length;
      const realValue = value.replaceAll('_', '');

      if (realValue.length < requiredLength && validateLength.includes(element_id)) {
        const error = { name: element_id, message: "Minimalna liczba znaków " + requiredLength }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

      if (!realValue) {
        const error = { name: element_id, message: "Nie może być puste" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

    });

    if (this.state.passwordRequired) {
      const password = document.getElementById('user_password').value;
      const password_confirmation = document.getElementById('user_password_confirmation').value;

      if (password.length < 1) {
        const error = { name: 'user_password', message: "Nie może być puste" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

      if (password_confirmation.length < 1) {
        const error = { name: 'user_password_confirmation', message: "Nie może być puste" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

      if (password != password_confirmation) {
        const error = { name: 'user_password_confirmation', message: "Musi być takie samo jak hasło" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }
    }

    if (this.state.errorMessages.length == 0) {
      this.props.nextStep();
      window.scrollTo(0, 0);
    }
  }

  fillBusinessPartnerData = (value) => {
    $('#main-content').addClass('loading')
    $('#continue').addClass('disabled')
    $('#back').addClass('disabled')

    this.setState({
      duringRequest: true,
      manuallyFillData: false
    });

    fetch(this.props.businessPartnerUrl, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        tax_number: value
      })
    })
    .then((response) => response.json())
    .then((data) => {

      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach(key => {
          const value = data[key];
          const element = document.getElementById(key)
          if (element) element.value = value

          if(element.id == 'user_regon') {
            this.setState({ user_regon: value });
          }
        });
      } else {
        this.setState({ manuallyFillData: true });
      }

      $('#main-content').removeClass('loading')
      $('#continue').removeClass('disabled')
      $('#back').removeClass('disabled')

      this.setState({ duringRequest: false })
    })
    .catch(() => {
      $('#main-content').removeClass('loading')
    });
  }

  checkForInternalClient = (value) => {
    $('#main-content').addClass('loading')
    $('#continue').addClass('disabled')
    $('#back').addClass('disabled')
    this.setState({ duringRequest: true })

    const email = document.getElementById('user_email').value;

    fetch(this.props.internalCustomerUrl, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        tax_number: value,
        email: email
      })
    })
    .then((response) => response.json())
    .then((data) => {
      $('#main-content').removeClass('loading')
      $('#continue').removeClass('disabled')
      $('#back').removeClass('disabled')

      const dataNotExists = Object.keys(data).length === 0;

      this.setState({ duringRequest: false })
      this.setState({ passwordRequired: dataNotExists });

      if (!dataNotExists) {
        this.props.goToNamedStep('clientExists');
      } else {
        this.fillBusinessPartnerData(value);
      }

    })
    .catch(() => {
      $('#main-content').removeClass('loading')
      document.getElementById('user_company_name').value = '';
      this.setState({ passwordRequired: true });
    });
  }

  changeInputs = (e) => {
    const element = e.target.id;
    const value = e.target.value;
    this.setState({[element]: value});
    const realValue = value.replace('_', '')
    if (element == 'user_vat_number' && realValue.length == 10 && !this.state.duringRequest) {
      this.checkForInternalClient(value);
    }
  }

  render() {
    const renderErrorMessage = (name) => {
      const allErrors = this.state.errorMessages;
      const namedError = allErrors.find(error => error.name === name);
      if (namedError !== undefined) {
        return (
          <div className="text-danger">{namedError.message}</div>
        );
      }
    }
    return (
      <React.Fragment>
        <h2>Dane ogólne</h2>
        <a href="#instructionModal" className="link" data-toggle="modal">Instrukcja rejestracji w Bio Service</a>
        <br></br>
        <br></br>
        <div className="control-group string required user_vat_number">
          <label className="string required control-label" htmlFor="user_vat_number">
            <abbr title="required">*</abbr> NIP
          </label>
          {this.state.manuallyFillData ? (
            <React.Fragment>
              <div className="text-info">Nie mogliśmy uzupełnić Twoich danych automatycznie, prosimy o uzupełnienie samodzielnie.</div>
            </React.Fragment>
          ) : ("")}
          <div className="controls">
            <InputMask mask="9999999999" className="string required" required="required" onChange={this.changeInputs} aria-required="true" type="text" name="user[vat_number]" value={this.state.user_vat_number} id="user_vat_number"></InputMask>
            { renderErrorMessage('user_vat_number') }
            <div className="text-danger" id="vat_number_errors"></div>
          </div>
        </div>
        <hr></hr>
        <div className="control-group string required user_name">
          <label className="string required control-label" htmlFor="user_name">
            <abbr title="required">*</abbr> Imię i Nazwisko osoby kontaktowej
          </label>
          <div className="controls">
            <input className="string required" required="required" aria-required="true" type="text" name="user[name]" id="user_name" />
            { renderErrorMessage('user_name') }
            <div className="text-danger" id="name_errors"></div>
          </div>
        </div>
        <div className="control-group string required user_company_name">
          <label className="string required control-label" htmlFor="user_company_name">
            <abbr title="required">*</abbr> Nazwa firmy
          </label>
          <div className="controls">
            <input className="string required" required="required" aria-required="true" type="text" name="user[company_name]" id="user_company_name" />
            { renderErrorMessage('user_company_name') }
            <div className="text-danger" id="company_name_errors"></div>
          </div>
        </div>
        <div className="control-group string required user_regon">
          <label className="string required control-label" htmlFor="user_regon">
            <abbr title="required">*</abbr> Regon
          </label>
          <div className="controls">
            <InputMask mask="999999999" className="string required" required="required" onChange={this.changeInputs} aria-required="true" value={this.state.user_regon} type="text" name="user[regon]" id="user_regon"></InputMask>
            { renderErrorMessage('user_regon') }
            <div className="text-danger" id="regon_errors"></div>
          </div>
        </div>
        <hr></hr>
        <div className="control-group tel required user_phone">
          <label className="tel required control-label" htmlFor="user_phone">
            <abbr title="required">*</abbr> Telefon
          </label>
          <div className="controls">
            <InputMask mask="+48 999999999" className="string tel required" required="required" onChange={this.changeInputs} aria-required="true" type="tel" name="user[phone]" id="user_phone" autoComplete="off" autoCorrect="off" value={this.state.user_phone} autoCapitalize="off" spellCheck="false"></InputMask>
            { renderErrorMessage('user_phone') }
            <div className="text-danger" id="phone_errors"></div>
          </div>
        </div>
        <div className="control-group string user_kh_code">
          <label className="string control-label" htmlFor="registration_kh_code">
            Nr klienta Inter Cars
          </label>
          <div className="controls">
            <InputMask mask="******" id="registration_kh_code" className="string" onChange={this.changeInputs} value={this.state.registration_kh_code} type="text" name="user[kh_code]"></InputMask>
            { renderErrorMessage('registration_kh_code') }
            <div className="text-danger" id="kh_code_errors"></div>
          </div>
        </div>
        {this.state.passwordRequired ? (
          <React.Fragment>
            <hr></hr>
            <div className="control-group password required user_password">
              <label className="password required control-label" htmlFor="user_password">
                <abbr title="required">*</abbr> Hasło do logowania w BS BIO
              </label>
              <div className="controls">
                <input className="password required" required="required" aria-required="true" type="password" name="user[password]" id="user_password"/>
                { renderErrorMessage('user_password') }
              </div>
            </div>
            <div className="control-group password required user_password_confirmation">
              <label className="password required control-label" htmlFor="user_password_confirmation">
                <abbr title="required">*</abbr> Potwierdź hasło
              </label>
              <div className="controls">
                <input className="password required" required="required" aria-required="true" type="password" name="user[password_confirmation]" id="user_password_confirmation"/>
                { renderErrorMessage('user_password_confirmation') }
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <br></br>
        <button type="button" id="back" onClick={this.props.previousStep} className="btn3 btn-light">
          Powrót
        </button>
        {this.state.duringRequest ? (
          <button type="button" id="continue"  className="btn3 btn-dark pull-right">
            Kontynuuj
          </button>
        ) : (
          <button type="button" id="continue" onClick={this.handleContinueClick} className="btn3 btn-dark pull-right">
            Kontynuuj
          </button>
        ) }

      </React.Fragment>
    );
  }
}

BasicStepComponent.propTypes = {
  vatNumber: PropTypes.string,
  regon: PropTypes.string,
  phone: PropTypes.string,
  khCode: PropTypes.string,
  authenticityToken: PropTypes.string.isRequired,
  internalCustomerUrl: PropTypes.string.isRequired,
  businessPartnerUrl: PropTypes.string.isRequired,
  passwordRequired: PropTypes.bool.isRequired
};

export default BasicStepComponent;
