import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';

class CompanyAddressStepComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
    };
  }

  handleContinueClick = () => {
    this.state.errorMessages = [];
    this.setState({ errorMessages: [] });

    const toValidate = ['user_headquarter_address_attributes_city',
                        'user_headquarter_address_attributes_address',
                        'user_headquarter_address_attributes_postal_code_id',
                        'user_headquarter_address_attributes_province_id']

    toValidate.map((element_id) => {
      const value = document.getElementById(element_id).value;

      if (!value) {
        const error = { name: element_id, message: "Nie może być puste" }
        this.state.errorMessages.push(error);
        this.setState({ errorMessages: this.state.errorMessages })
      }

    });
    if (this.state.errorMessages.length == 0) {
      this.props.nextStep();
    }
  }

  render() {
    const renderErrorMessage = (name) => {
      const allErrors = this.state.errorMessages;
      const namedError = allErrors.find(error => error.name === name);
      if (namedError !== undefined) {
        return (
          <div className="text-danger">{namedError.message}</div>
        );
      }
    }
    return (
      <React.Fragment>
        <h2><i className="fa-solid fa-circle-info"></i></h2>
        <div className="control-group string required user_headquarter_address_city">
          <label className="string required control-label" htmlFor="user_headquarter_address_attributes_city">
            <abbr title="required">*</abbr> Miejscowość
          </label>
          <div className="controls">
            <input className="string required" required="required" aria-required="true" type="text" name="user[headquarter_address_attributes][city]" id="user_headquarter_address_attributes_city" />
            { renderErrorMessage('user_headquarter_address_attributes_city') }
          </div>
        </div>
        <div className="control-group string required user_headquarter_address_city">
          <label className="string required control-label" htmlFor="user_headquarter_address_attributes_address">
            <abbr title="required">*</abbr> Ulica i numer lokalu
          </label>
          <div className="controls">
            <input className="string required" required="required" aria-required="true" type="text" name="user[headquarter_address_attributes][address]" id="user_headquarter_address_attributes_address" />
            { renderErrorMessage('user_headquarter_address_attributes_address') }
          </div>
        </div>
        <div className="control-group string required autocomplete user_headquarter_address_postal_code_id">
          <label className="string required control-label" htmlFor="user_headquarter_address_attributes_postal_code_id">
            <b><abbr title="required">*</abbr> Kod pocztowy (wybierz z listy)</b>
          </label>
          <div className="controls">
            <input
              className="autocomplete optional form-control text-center postal_code ui-autocomplete-input"
              placeholder="proszę wpisać"
              data-id-element="#autocomplete_selected_postal_code"
              data-autocomplete="/users/autocomplete_postal_code"
              type="text"
              name="user[headquarter_address_attributes][postal_code_id]"
              id="user_headquarter_address_attributes_postal_code_id"
              autoComplete="off">
            </input>
            { renderErrorMessage('user_headquarter_address_attributes_postal_code_id') }
          </div>
        </div>
        <div className="control-group select required user_headquarter_address_province_id">
          <label className="select required control-label" htmlFor="user_headquarter_address_attributes_province_id">
            <abbr title="required">*</abbr> Województwo
          </label>
          <div className="controls">
            { ReactHtmlParser(this.props.provincesHtml) }
            { renderErrorMessage('user_headquarter_address_attributes_province_id') }
          </div>
        </div>
        <button type="button" onClick={this.props.previousStep} className="btn3 btn-light">
          Powrót
        </button>
        <button type="button" onClick={this.handleContinueClick} className="btn3 btn-dark pull-right">
          Kontynuuj
        </button>
      </React.Fragment>
    );
  }
}

CompanyAddressStepComponent.propTypes = {
  provincesHtml: PropTypes.string.isRequired
};

export default CompanyAddressStepComponent;
